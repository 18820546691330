import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`📺 Séries`}</h1>
    <p>{`Hay maneras muy entretenidas de aprender un idioma nuevo, ver programas televisivos, documentales, series y películas es una de ellas.
Desde el punto de vista del aprendizaje de idiomas, las series pueden ser utilizadas como herramientas para desarrollar las habilidades lingüísticas.
Te recomendamos verlas en su idioma original para mejorar tu nivel y reconocer las palabras nuevas, las frases y expresiones que logres entender.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://en.wikipedia.org/wiki/The_Forest_(TV_series)"
      }}><strong parentName="a">{`La Fôret`}</strong></a></h2>
    <p>{`En Montfaucon, un tranquilo pueblo de la región de las Ardenas, la teniente Virginie Musso comienza su trabajo. Mientras, una joven desaparece en el bosque.
Todos piensan que volverá, pero pronto la evidencia sugiere que ha sucedido algo grave.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/CaKk_ZBBG7s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Le_Chalet_(serie_de_televisi%C3%B3n)"
      }}><strong parentName="a">{`Le Chalet`}</strong></a></h2>
    <p>{`Unos amigos se reúnen en una casa de campo aislada en los Alpes franceses durante las vacaciones. Todo parece ser idílico hasta que un oscuro secreto del pasado sale a la luz.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/7-lEZVRYulQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Lupin_(serie)"
      }}><strong parentName="a">{`Lupin`}</strong></a></h2>
    <p>{`Basada en la historia de Arsène Lupin, el ladrón Assane Diop se propone vengar a su padre de las injusticias sufridas por parte de una familia adinerada.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/yX5Py7lL5dw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://finde.latercera.com/series-y-peliculas/recursos-inhumanos-serie-netflix/"
      }}><strong parentName="a">{`Dérapages`}</strong></a></h2>
    <p>{`Desempleado y desesperado por cambiar su vida, Alain Delambre está listo para hacer cualquier cosa para conseguir un trabajo en la compañía Exxya.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/N4iOjVQuumk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Marianne_(serie_de_televisi%C3%B3n)"
      }}><strong parentName="a">{`Marianne`}</strong></a></h2>
    <p>{`Una famosa escritora vuelve a su ciudad natal y descubre que el espíritu maligno que invade sus sueños está causando estragos en el mundo real.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/wMofLWJCV0U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Mitoman%C3%ADa"
      }}><strong parentName="a">{`Mitomanía`}</strong></a></h2>
    <p>{`Elvira siente que se vuelve cada vez más invisible para su propia familia y espera más amor y atención. Entonces, ella finge estar gravemente enferma. Una mentira con consecuencias imprevistas.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/pMiF7QOTXXM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Zone_Blanche_(serie_de_televisi%C3%B3n)"
      }}><strong parentName="a">{`Zone blanche`}</strong></a></h2>
    <p>{`Un inspector de policía investiga una serie de asesinatos en una ciudad donde la tasa de homicidios es seis veces mayor que en el resto del país.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/b3lA1N4htzc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/Into_the_Night_(serie)"
      }}><strong parentName="a">{`Into the Night`}</strong></a></h2>
    <p>{`Cuando un misterioso desastre cósmico golpea la Tierra, los sobrevivientes en un vuelo nocturno desde Bruselas tratan de encontrar refugio y escapar de los rayos del sol.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/DLq_-xciim0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/La_revoluci%C3%B3n_(serie_de_televisi%C3%B3n)"
      }}><strong parentName="a">{`La revolution`}</strong></a></h2>
    <p>{`En 1787, Joseph Guillotin descubre que un virus se está propagando entre la nobleza y ha llevado a asesinar a los plebeyos.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/aGwUITVlzwQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2><a parentName="h2" {...{
        "href": "https://es.wikipedia.org/wiki/La_Mantis"
      }}><strong parentName="a">{`La mantis`}</strong></a></h2>
    <p>{`Décadas después de su captura, un asesino en serie acepta ayudar a la policía a atrapar a su imitador, un asesino que reproduce sus crímenes de la misma manera.`}</p>
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/hsGH23ja7SA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      